import React from "react";
import SEO from "@src/components/SEO";
import Container from "@src/components/Container";
import { Flex } from "@src/components/Boxes";
import { Title, Space } from "@src/components/nessie-web";

const ContestWinner = () => {
  return (
    <>
      <SEO
        title="Dojo Islands Dream School 2023 Challenge Winner"
        noindex={true}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Dojo Islands Dream School 2023 Challenge Winner",
        }}
      />
      <Container paddingTop="60px" paddingBottom="60px" css={{ textAlign: "center" }}>
        <Title size="1">Congrats to our ‘Dojo Island Dream School’ Winner</Title>
        <Title size="2">Ed. P’s 5th grade class from J. Mason Tomlin Elementary School</Title>
        <video
          src="https://static.classdojo.com/img/2023/12/dojo-islands-winner-compressed.mp4"
          width="100%"
          controls
          style={{ borderRadius: "24px", marginTop: "60px" }}
        >
          <track default kind="captions" src="/captions/contest.vtt" srcLang="en" />
        </video>
      </Container>
    </>
  );
};

export default ContestWinner;
